/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'bounding-box-circles': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1a1 1 0 100 2 1 1 0 000-2M0 2a2 2 0 013.937-.5h8.126A2 2 0 1114.5 3.937v8.126a2 2 0 11-2.437 2.437H3.937A2 2 0 111.5 12.063V3.937A2 2 0 010 2m2.5 1.937v8.126c.703.18 1.256.734 1.437 1.437h8.126a2 2 0 011.437-1.437V3.937A2 2 0 0112.063 2.5H3.937A2 2 0 012.5 3.937M14 1a1 1 0 100 2 1 1 0 000-2M2 13a1 1 0 100 2 1 1 0 000-2m12 0a1 1 0 100 2 1 1 0 000-2"/>',
    },
});
